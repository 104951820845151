import React from 'react'

const searchblog = ({value,handlesearchkey,clearsearch,formsubmit}) => {
  return (
    <div className='searchbar-wrap '>
        <form className="form"onSubmit={formsubmit}>
            <input 
            type="text" 
            placeholder='Search blog'
            onChange={handlesearchkey}
             value={value}
             />
            <div>
             <span onClick={clearsearch}> x </span> 
             </div>
             <button className='submit'>Search</button>
        </form>

    </div>
  )
}

export default searchblog